import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Img } from 'react-image';
import Highlighter from 'react-highlight-words';
import { Trans, withI18n } from '@lingui/react';
import { Portal } from 'react-portal';
import format from 'date-fns/format';
import cx from 'classnames';
import find from 'lodash/find';

import LimitText from './utils/LimitText';
import { /*getAssetThumbUrl, getEtimImageUrl, getAssetURL,*/ getProductImagePath } from '../api';
// import noPhotoImg from '../images/no-photo.png';
import ArticleDetailsModal from './ArticleDetailsModal';
import { getLocaleFromLanguageCode } from '../intl-helpers';
import { getDetailValue } from '../utils/dataFormaters';
import { AddToCollectionBtn } from './AddToCollectionModal';

function isOdd(n) {
    return Math.abs(n % 2) === 1;
}

function getProperty(code, productProperties) {
    return productProperties[code];
}

function getPropertyValue(code, itemProps) {
    return find(itemProps, (prop) => prop.property === code);
}

class RowBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        };
    }

    getPropertyDetailValue(code) {
        const { item, language, productProperties, i18n, priceDecimalPrecision } = this.props;

        console.log(item.toJS());

        const locale = getLocaleFromLanguageCode(language);

        const result = getDetailValue(
            getPropertyValue(code, item.children),
            getProperty(code, productProperties),
            locale,
            productProperties,
            i18n,
            priceDecimalPrecision
        );

        if (!result) {
            return '-';
        }

        return result;
    }

    getValiditaPrezzo() {
        const { item } = this.props;

        if (item.prezzo_attuale && item.prezzo_attuale.data_inizio_validita_prezzo) {
            return format(item.prezzo_attuale.data_inizio_validita_prezzo.date, 'DD/MM/YYYY');
        }

        return null;
    }

    openModal = () => {
        this.setState({
            showModal: true,
        });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    };

    render() {
        const {
            index,
            item,
            // productInfoMap,
            // language,
            onSelect,
            q,
            // selectable,
            availableProps,
            showLists,
        } = this.props;

        // console.log(item.toJS());

        // console.log(availableProps);

        //const locale = getLocaleFromLanguageCode(language);

        const prezzo = this.getPropertyDetailValue('prezzo_listino');
        const validita_prezzo = this.getPropertyDetailValue('data_inizio_validita_prezzo');

        // console.log(prezzo);
        // console.log(validita_prezzo);

        // console.log(item.toJS());

        // console.log(showLists);

        return (
            <Fragment>
                {this.state.showModal && (
                    <Portal>
                        <ArticleDetailsModal
                            article={item}
                            onClose={this.closeModal}
                            containerId={`article-detail-modal-${item.id}`}
                        />
                    </Portal>
                )}
                <div
                    className={cx(
                        'card c-zoom-in rounded-sm col-span-2 lg:col-span-1',
                        isOdd(index) ? 'bg-alternate-row' : 'bg-white'
                    )}
                    onClick={this.openModal}
                >
                    {showLists && (
                        <div
                            className="flex items-center ml-2.5 pt-0.5"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <label
                                className="checkmark-container"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onSelect(item.id);
                                }}
                            >
                                <input
                                    type="checkbox"
                                    checked={item.isSelected}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={() => {}}
                                />
                                <span className="checkmark"></span>
                            </label>
                            {/* <span className="">
                                <label
                                    className="form-checkbox form-checkbox-inline"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onSelect(item.id);
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        checked={item.isSelected}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() => {}}
                                    />
                                    <i className="form-icon" />
                                </label>
                            </span> */}
                            <AddToCollectionBtn itemId={item.id} />
                        </div>
                    )}
                    <div className="card-image-wrapper lg:flex pl-2.5 pr-2 pb-2 pt-0">
                        <div className="card-image lg:flex-grow">
                            <div
                                className="table-img-preview m-auto lg:m-0"
                                style={{ height: '130px' }}
                            >
                                <Img
                                    src={getProductImagePath(item)}
                                    // src={
                                    //     item.metadata && item.metadata.immagine_prodotto
                                    //         ? getAssetURL(item.metadata.immagine_prodotto.url)
                                    //         : noPhotoImg
                                    // }
                                    className="img-responsive m-a"
                                    loader={
                                        <div className="rounded bg-secondary table-img-preview" />
                                    }
                                    style={{
                                        maxHeight: '125px',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="card-image-side lg:flex-grow-0 pl-1 min-h-[4.5rem] hidden lg:flex">
                            <div>
                                <div className="text-label text-uppercase text-small">
                                    {availableProps.includes('unita_ordine') && <Trans id="um" />}
                                </div>
                                <div
                                    className="tooltip"
                                    data-tooltip={this.getPropertyDetailValue('unita_ordine')}
                                >
                                    {this.getPropertyDetailValue('unita_ordine')}
                                </div>
                                <hr className="hr-light" />
                                <div className="text-label text-uppercase text-small">
                                    {availableProps.includes('unita_per_prezzo') && (
                                        <Trans id="conf" />
                                    )}
                                </div>
                                {this.getPropertyDetailValue('unita_per_prezzo')}
                            </div>
                        </div>
                    </div>
                    <div className="card-header">
                        <div className="card-title h6">
                            <Highlighter
                                searchWords={[q]}
                                textToHighlight={this.getPropertyDetailValue('codice_articolo')}
                            />
                        </div>
                        <div className="card-subtitle">
                            <LimitText
                                limit={25}
                                highlight={q}
                                text={this.getPropertyDetailValue('descrizione_articolo')?.replace( /(<([^>]+)>)/ig, '')}
                            />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="card-body-wrapper">
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('descrizione_marca') && (
                                    <Trans id="brand" />
                                )}
                            </div>
                            <LimitText
                                limit={16}
                                text={this.getPropertyDetailValue('descrizione_marca')}
                            />
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('serie') && <Trans id="series" />}
                            </div>
                            <LimitText limit={16} text={this.getPropertyDetailValue('serie')} />
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('linee') && <Trans id="line" />}
                            </div>
                            <LimitText limit={16} text={this.getPropertyDetailValue('linea')} />
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('modello') && <Trans id="model" />}
                            </div>
                            <LimitText limit={16} text={this.getPropertyDetailValue('modello')} />
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="lg:hidden">
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('unita_ordine') && <Trans id="um" />}
                            </div>
                            <div
                                className="tooltip"
                                data-tooltip={this.getPropertyDetailValue('unita_ordine')}
                            >
                                {this.getPropertyDetailValue('unita_ordine')}
                            </div>
                            <hr className="hr-light" />
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('unita_per_prezzo') && <Trans id="conf" />}
                            </div>
                            {this.getPropertyDetailValue('unita_per_prezzo')}
                        </div>
                        {prezzo !== null && prezzo !== '-' ? (
                            <Fragment>
                                <div className="table-price-lg">{`€ ${prezzo}`}</div>
                                <div className="table-date-sm text-error">
                                    {validita_prezzo && validita_prezzo !== '-' && (
                                        <Fragment>
                                            <Trans id="from" /> {validita_prezzo}
                                        </Fragment>
                                    )}
                                </div>
                            </Fragment>
                        ) : (
                            <>
                                <div className="table-price-lg" style={{ height: '53px' }}>
                                    -
                                </div>
                                <div className="table-date-sm text-error"></div>
                            </>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

RowBlock.propTypes = {
    bg: PropTypes.string,
    index: PropTypes.number,
    onCloseButtonClick: PropTypes.func,
    onSelect: PropTypes.func,
    showDetails: PropTypes.bool,
    showLists: PropTypes.bool,
};

export default withI18n()(RowBlock);
